import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaEnvelope,
} from 'react-icons/fa';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "map_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => (
  <>

  <Hero title="Page not found! (404)"
        subtitle="Sorry, the page you are looking for doesn't exist! Please navigate to the pages above or contact us."
        fluid={data.hero.childImageSharp.fluid}
  />

  <section className="section">
    
  </section>

    <section className="section">
      <div className="container subtitle is-thin">

        <p>
          <a href="mailto:admin@pansurg.org">
            <FaEnvelope color="midBlue"/>
            &nbsp;
            admin@pansurg.org
          </a>
        </p>
      </div>
    </section>
  </>
)
